import { Action, createAction, createReducer, PayloadAction } from '@reduxjs/toolkit';
import { ofType } from 'redux-observable';
import { from, Observable, of } from 'rxjs';
import { catchError, finalize, map, switchMap, tap } from 'rxjs/operators';
import Auth from '../../services/auth';
import { getAuthorityUrl, getImlp2Url, getImlpUrl, getInferredEnvironment } from '../../services/network';
import UniversalLoginOidc from '../../services/oidc';
import { parseQueryString, parseUrlPart } from '../../services/queryStringParameters';
import { tokenReceived } from './app';
import { log } from '../../services/logger';
import { AuthSource } from '../../constants/authSources';

// Export for testing purposes
export function __getUserManager() {
	return Auth.getAuthProvider() === AuthSource.IMLP
		? imlpUserManager
		: Auth.getAuthProvider() === AuthSource.IMLP2
		? imlp2UserManager
		: defaultUserManager;
}

function getExtraQueryParams(authSource: AuthSource) {
	return authSource === AuthSource.IMLP2
		? { extraQueryParams: { useImlp2: 'true' } }
		: authSource === AuthSource.IMLP
		? { extraQueryParams: { useImlp: 'true' } }
		: {};
}

const defaultUserManager = UniversalLoginOidc.createDefaultUserManager();
const imlpUserManager = UniversalLoginOidc.createImlpUserManager(AuthSource.IMLP);
const imlp2UserManager = UniversalLoginOidc.createImlpUserManager(AuthSource.IMLP2);

/**
 * Action creators
 */

export const completeSignin = createAction('universal-login-page/oidc/COMPLETE_SIGNIN');
export const handlePostLogout = createAction(
	'universal-login-page/oidc/HANDLE_POST_LOGOUT',
	(logoutCallbackUrl: string, resumeUrl: string) => ({
		payload: { logoutCallbackUrl, resumeUrl }
	})
);
export const handlePostLogoutComplete = createAction('universal-login-page/oidc/HANDLE_POST_LOGOUT_COMPLETE');
export const signinInProgress = createAction<string>('universal-login-page/oidc/SIGNIN_IN_PROGRESS');
export const signinError = createAction<Error | object>('universal-login-page/oidc/SIGNIN_ERROR');
export const signinErrorHandled = createAction<Error | object>('universal-login-page/oidc/SIGNIN_ERROR_HANDLED');
export const signinSubmitted = createAction('universal-login-page/oidc/SIGNIN_SUBMITTED');
export const signinSuccess = createAction('universal-login-page/oidc/SIGNIN_SUCCESS');

/**
 * Reducer
 */

export interface OidcState {
	isInProgress: boolean;
	isSubmitted: boolean;
	returnUrl?: string;
	error?: Error | object;
}

const initialState: OidcState = {
	isInProgress: false,
	isSubmitted: false
};

const reducer = createReducer(initialState, builder =>
	builder
		.addCase(signinInProgress, (state, { payload }) => ({
			...state,
			returnUrl: payload,
			isInProgress: true
		}))
		.addCase(signinSubmitted, state => ({
			...state,
			isInProgress: false,
			isSubmitted: true
		}))
		.addCase(signinSuccess, state => ({
			...state,
			isInProgress: false,
			isSubmitted: false
		}))
		.addCase(signinError, (state, { payload }) => ({
			...state,
			error: payload
		}))
);

export default reducer;

/**
 * Epics
 */

export function completeSigninEpic(action$: Observable<Action>) {
	return action$.pipe(
		ofType(completeSignin.type),
		switchMap(() => {
			const userManager = __getUserManager();
			return from(userManager.signinRedirectCallback()).pipe(
				switchMap(user => {
					// eslint-disable-next-line @typescript-eslint/no-explicit-any
					const { access_token: accessToken } = user as any;
					return from([tokenReceived(accessToken), signinSuccess()]);
				}),
				catchError(err => of(signinError(err)))
			);
		})
	);
}

export function handlePostLogoutEpic(action$: Observable<PayloadAction<{ resumeUrl: string; logoutCallbackUrl: string }>>) {
	return action$.pipe(
		ofType(handlePostLogout.type),
		switchMap(({ payload }) => {
			// /signin is default if no location was specified
			const { resumeUrl = '/signin', logoutCallbackUrl } = payload || {};
			return Auth.logoutCallback(logoutCallbackUrl).pipe(
				map(() => handlePostLogoutComplete()),
				catchError(err => {
					console.error(err);
					return of(handlePostLogoutComplete());
				}),
				finalize(() => {
					// If you were impersonating, go back from whence you came
					window.location.replace(resumeUrl);
				})
			);
		})
	);
}

export function signinErrorEpic(action$: Observable<PayloadAction<Error | object>>) {
	return action$.pipe(
		ofType(signinError.type),
		tap(({ payload: error }) => {
			console.error(error);
			const userManager = __getUserManager();
			const message = error instanceof Error ? error?.message : '';
			// Only re-trigger signinRedirect if it's the "No matching state" error
			console.log(message);
			if (/no matching state.*/i.test(message)) {
				const extraParams = getExtraQueryParams(Auth.getAuthProvider());
				userManager.signinRedirect(extraParams);
			}
		}),
		map(({ payload }) => signinErrorHandled(payload))
	);
}

/**
 * Thunks
 */

export function signOut() {
	return () => {
		const userManager = __getUserManager();
		const extraParams = getExtraQueryParams(Auth.getAuthProvider());
		userManager.signoutRedirect(extraParams);
	};
}

export function startSignin() {
	return () => {
		const returnUrl = parseQueryString['returnUrl'];
		if (returnUrl) {
			const returnUrlParts = parseUrlPart(returnUrl);
			const useImlp2 = /true|(^$)/i.test(parseQueryString['useImlp2']) || /true|(^$)/i.test(returnUrlParts['useImlp2']);
			const useIlAuth = /true|(^$)/i.test(parseQueryString['useIlAuth']) || /true|(^$)/i.test(returnUrlParts['useIlAuth']);
			handleSignin(useIlAuth ? AuthSource.ILAuth : useImlp2 ? AuthSource.IMLP2 : AuthSource.IMLP);
		} else {
			const useIlAuth = /true|(^$)/i.test(parseQueryString['useIlAuth']);
			const useImlp2 = /true|(^$)/i.test(parseQueryString['useImlp2']);
			handleSignin(useIlAuth ? AuthSource.ILAuth : useImlp2 ? AuthSource.IMLP2 : AuthSource.IMLP);
		}
	};
}

function handleSignin(authSource: AuthSource) {
	const env = getInferredEnvironment(window.location.host);
	const controller = new AbortController();
	const signal = controller.signal;

	const { launch, force } = parseQueryString;
	if (launch) {
		window.sessionStorage.setItem('requestedProduct', launch);
		if (force) {
			window.sessionStorage.setItem('requestedProductAutoLaunch', 'force');
		}
	}

	if (authSource === AuthSource.IMLP) {
		const imlpUrl = getImlpUrl(env);
		Auth.selectImlpProvider();
		const timeout = setTimeout(() => {
			controller.abort();
			Auth.selectIlAuthProvider();
			defaultUserManager.signinRedirect();
			console.error(`Timed out while loading ${imlpUrl}`);
		}, 5000);
		fetch(imlpUrl + '/.well-known/openid-configuration', {
			headers: { 'Cache-control': 'no-cache', pragma: 'no-cache' },
			signal
		})
			.then(response => {
				clearTimeout(timeout);
				if (response.ok) {
					const extraParams = getExtraQueryParams(AuthSource.IMLP);
					Auth.selectImlpProvider();
					imlpUserManager.signinRedirect(extraParams);
				} else {
					Auth.selectIlAuthProvider();
					defaultUserManager.signinRedirect();
				}
			})
			.catch(error => {
				console.error(`Failed to load ${imlpUrl}: ${error}`);
				log({ error: 'failure trying to load IMLP. Fell back to IL Auth.' })
					.toPromise()
					.finally(() => {
						Auth.selectIlAuthProvider();
						defaultUserManager.signinRedirect();
					});
			});
	} else if (authSource === AuthSource.IMLP2) {
		const imlpUrl = getImlp2Url(env);
		Auth.selectImlp2Provider();
		const timeout = setTimeout(() => {
			controller.abort();
			Auth.selectIlAuthProvider();
			defaultUserManager.signinRedirect();
			console.error(`Timed out while loading ${imlpUrl}`);
		}, 5000);
		fetch(imlpUrl + '/.well-known/openid-configuration', {
			headers: { 'Cache-control': 'no-cache', pragma: 'no-cache' },
			signal
		})
			.then(response => {
				clearTimeout(timeout);
				if (response.ok) {
					const extraParams = getExtraQueryParams(AuthSource.IMLP2);
					Auth.selectImlp2Provider();
					imlp2UserManager.signinRedirect(extraParams);
				} else {
					Auth.selectIlAuthProvider();
					defaultUserManager.signinRedirect();
				}
			})
			.catch(error => {
				console.error(`Failed to load ${imlpUrl}: ${error}`);
				log({ error: 'failure trying to load IMLP. Fell back to IL Auth.' })
					.toPromise()
					.finally(() => {
						Auth.selectIlAuthProvider();
						defaultUserManager.signinRedirect();
					});
			});
	} else {
		Auth.selectIlAuthProvider();
		defaultUserManager.signinRedirect();
	}
}

export function clearStaleState() {
	return () => {
		defaultUserManager.clearStaleState();
		imlpUserManager.clearStaleState();
		imlp2UserManager.clearStaleState();
		localStorage.removeItem('oidcSessionId');
	};
}
