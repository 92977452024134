import { createSelector } from '@reduxjs/toolkit';
import some from 'lodash-es/some';
import uniq from 'lodash-es/uniq';
import { AssessmentProviders } from '../../constants/assessmentProviders';
import { AssessmentStatuses } from '../../constants/assessmentStatuses';
import { ProductDisabledReasons } from '../../constants/productDisabledReasons';
import { RootState } from '../root';

export function selectProducts(state: RootState) {
	return state.productSelection.products;
}

export function selectProductsError(state: RootState) {
	return state.productSelection.productsError;
}

export function selectProductsLoading(state: RootState) {
	return state.productSelection.loading || !state.productSelection.flagsLoaded;
}

export function selectRecommendedAssessmentProvider(state: RootState) {
	return state.productSelection.recommendedAssessmentProvider;
}

export function selectRecommendedAssessmentUrl(state: RootState) {
	return state.productSelection.recommendedAssessmentUrl;
}

export function selectRosterDashboard(state: RootState) {
	return state.productSelection.rosterDashboard;
}

export function selectScheduleDashboard(state: RootState) {
	return state.productSelection.scheduleDashboard;
}

export function selectSetupRequired(state: RootState) {
	return state.productSelection.setupRequired;
}

export const selectAssessmentProviders = createSelector([selectProducts], products =>
	uniq((products || []).filter(product => !!product.assessmentProvider).map(product => product.assessmentProvider))
);

export const selectAssessmentRecommended = createSelector([selectRecommendedAssessmentProvider], provider => !!provider);

export const selectDefaultAssessmentProvider = createSelector([selectAssessmentProviders], providers =>
	(providers || []).reduce((defaultProvider, current) => {
		switch (current) {
			case AssessmentProviders.NWEA:
			case AssessmentProviders.ATI:
			case AssessmentProviders.IPA:
				return current;
			default:
				return defaultProvider;
		}
	}, AssessmentProviders.ILE)
);

const selectDefaultAssessmentStatusAndUrl = createSelector(
	[selectProducts, selectDefaultAssessmentProvider],
	(products, defaultAssessmentProvider) =>
		(products || [])
			.filter(prod => prod.assessmentProvider === defaultAssessmentProvider)
			.map(({ assessmentStatus, assessmentUrl }) => ({
				status: assessmentStatus,
				url: assessmentUrl
			}))
			.reduce((accumulator, assessment) => {
				if (
					assessment.status === AssessmentStatuses.None ||
					(assessment.status === AssessmentStatuses.InProgress && accumulator.status !== AssessmentStatuses.None)
				) {
					return assessment;
				}
				return accumulator;
			}, {} as { status?: AssessmentStatuses; url?: string })
);

export const selectDefaultAssessmentStatus = createSelector([selectDefaultAssessmentStatusAndUrl], statusAndUrl => statusAndUrl?.status);

export const selectDefaultAssessmentUrl = createSelector([selectDefaultAssessmentStatusAndUrl], statusAndUrl => statusAndUrl?.url);

export const selectHasLockedProducts = createSelector([selectProducts], products => some(products, prod => prod.assessmentRequired));

export const selectNweaRosteringError = createSelector([selectProducts], products =>
	some(products, ({ disabledReason }) => disabledReason === ProductDisabledReasons.NotRosteredWithNWEA)
);

export const selectNotLicensedProducts = createSelector([selectProducts], products =>
	some(products, ({ disabledReason }) => disabledReason === ProductDisabledReasons.OrganizationNotLicensed)
);

export function selectUserFlagEvaluationResults(state: RootState) {
	return state.productSelection.evaluationResults;
}

export function selectAnalyticsInitialized(state: RootState) {
	return state.productSelection.analyticsInitialized;
}

export function selectAnalyticsUserIdentified(state: RootState) {
	return state.productSelection.analyticsUserIdentified;
}
